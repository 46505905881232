<template>
  <section>
    <aDivider class="mb-0 mt-0" orientation="left">
      SELECIONAR PAXES/PRODUTOS <a-divider type="vertical" />
      {{ tempInvoice.product_supplier_name }} <a-divider type="vertical" />
      {{ tempInvoice.id }} - {{ tempInvoice.name }}
      <a-divider type="vertical" />
      {{ tempInvoice.period | formatPeriod }}
      <a-divider type="vertical" /> TOTAL CONTRATOS: {{ contract.meta.total }}
    </aDivider>

    <aBadge
      v-if="
        contract.list.length &&
        !['Pendente', 'Pago', 'Recebido', 'Na fila'].includes(activeStatusTab)
      "
      class="fly-add-bills"
      :count="selectedPaxes.length"
    >
      <aButton
        class="add"
        :count="totalInvoicesInQueue"
        type="primary"
        size="large"
        shape="circle"
        :loading="loadingAddToSelectedPaxes"
        @click="onClickAddToSelectedPaxes"
      >
        <aTooltip placement="left">
          <template #title> Clique para adicionar à fatura </template>
          <aIcon v-if="!loadingAddToSelectedPaxes" type="plus" />
        </aTooltip>
      </aButton>
    </aBadge>

    <aTooltip
      v-if="activeStatusTab === 'Na fila'"
      placement="top"
      class="fly-remove-paxes"
      title="Clique para remover paxes da fila"
    >
      <aButton
        type="danger"
        size="large"
        shape="circle"
        :loading="loadingRemoveSelectedPaxes"
        :disabled="invoicesInQueue.length > 0 ? false : true"
        @click="onClickRemoveToSelectedPaxes"
      >
        <a-icon v-if="!loadingRemoveSelectedPaxes" type="close" />
      </aButton>
    </aTooltip>

    <aBadge
      v-if="activeStatusTab === 'Na fila'"
      class="fly-add-bills"
      :count="invoicesInQueue.length"
    >
      <aButton
        class="gen"
        type="primary"
        size="large"
        :disabled="invoicesInQueue.length > 0 ? false : true"
        @click="openGenerateInvoiceModal = true"
      >
        Gerar fatura
      </aButton>
    </aBadge>

    <aTabs
      class="mt-20"
      type="card"
      v-model="activeStatusTab"
      @change="onChangePaxesStatusTabs"
    >
      <aTabPane key="Em aberto" tab="Em aberto" :disabled="contract.loading" />
      <aTabPane key="Na fila" :disabled="contract.loading">
        <template #tab>
          <span class="mr-5" style="color: #108ee9"> Na fila </span>
          <aBadge
            v-if="totalInvoicesInQueue !== 0"
            class="relative ml-10 mr-10"
            :count="totalInvoicesInQueue"
            :number-style="{ backgroundColor: '#108ee9' }"
            style="top: 5px"
            >.</aBadge
          >
        </template>
      </aTabPane>
    </aTabs>

    <aAlert
      class="f12 mb-20"
      message="Selecione grupos de paxes e clique no botão (+) no canto inferior direito para fazer separações entre vendas."
      type="info"
      banner
    />

    <aRow class="mt-20" :gutter="20">
      <aCol :span="3">
        <div class="travel-input">
          <aInput placeholder="ID Contrato" v-model="filterContractId" />
        </div>
      </aCol>

      <aCol class="mb-10 ml-5 travel-input" :span="4">
        <aInput
          placeholder="ID venda + Enter"
          v-model="contract.filters.saleId"
          @pressEnter="getSupplierPaxesInContract()"
        />
      </aCol>

      <aCol :span="3">
        <div class="travel-input">
          <a-input
            placeholder="Localizador + Enter"
            v-model="contract.filters.locator"
            @pressEnter="getSupplierPaxesInContract()"
          />
        </div>
      </aCol>

      <aCol :span="5">
        <div class="travel-input">
          <a-input
            placeholder="Nome do viajante + Enter"
            v-model="contract.filters.travellerName"
            @pressEnter="getSupplierPaxesInContract()"
          />
        </div>
      </aCol>

      <aCol :span="7">
        <div class="field checkboxes">
          <a-checkbox-group style="width: 100%" v-model="productType">
            <a-checkbox
              v-for="(item, index) of contract.filters.productsCategory.list"
              :key="index"
              :value="item.value"
            >
              {{ item.label }}
            </a-checkbox>

            <a-checkbox :key="5" value="package"> Pacote </a-checkbox>
          </a-checkbox-group>
        </div>
      </aCol>
    </aRow>

    <aTable
      class="travel-table"
      :columns="columns"
      :data-source="
        frontendFilters(newContractList(contract.list)).filteredListCurrentPage
      "
      :loading="contract.loading"
      :pagination="false"
      :expandIconAsCell="false"
      :expandIconColumnIndex="1"
      :scroll="{ x: 1300 }"
      :row-selection="{
        onChange: onSelectChange,
        selectedRowKeys: selectedPaxes,
        getCheckboxProps: (record) => ({
          props: {
            disabled: this.disabledStatusArr.includes(record.invoice_status),
          },
        }),
      }"
    >
      <div slot="sale_id" slot-scope="record">{{ record.raw.sale_id }}</div>

      <div slot="product" slot-scope="record">
        <div class="dotted-phrase upper">
          <aTooltip :title="`${record.product_id} - ${record.product}`">
            {{ record.product }}
          </aTooltip>
        </div>
      </div>

      <div slot="traveller_name" slot-scope="record">
        <div class="truncate upper">
          <aTooltip placement="left">
            <template #title>
              <div v-html="record.traveller_name"></div
            ></template>
            {{ record.traveller_name }}
          </aTooltip>
        </div>
      </div>

      <div slot="supplier" slot-scope="record">
        <div class="dotted-phrase upper">
          <aTooltip :title="record.supplier">
            {{ record.supplier }}
          </aTooltip>
        </div>
      </div>

      <div slot="release_date" slot-scope="record">
        {{ record.release_date | formatMultiDates }}
      </div>

      <div slot="product_type" slot-scope="record">
        <aTooltip :title="record.product">
          <img
            class="grayscale"
            v-if="record.product_type === 'flight'"
            src="@/assets/images/dashboard/contracts/flight-ico.png"
            alt="img"
            width="20"
            style="opacity: 0.7"
          />

          <img
            class="grayscale"
            v-if="record.product_type === 'hotel'"
            src="@/assets/images/dashboard/contracts/hotel-ico.png"
            alt="img"
            width="20"
            style="opacity: 0.5"
          />

          <img
            class="grayscale"
            v-if="record.product_type === 'service'"
            src="@/assets/images/dashboard/contracts/services-ico.png"
            alt="img"
            width="19"
            style="opacity: 0.6"
          />

          <a-icon v-if="record.product_type === 'package'" type="star" />
        </aTooltip>
      </div>

      <div slot="ticket_value" slot-scope="record">
        {{ record.ticket_value | formatListPriceNumAndStr }}
      </div>

      <div slot="ticket_tax_value" slot-scope="record">
        {{ record.ticket_tax_value | formatListPriceNumAndStr }}
      </div>

      <div slot="incomings" slot-scope="record">
        {{ record.incomings | formatListPriceNumAndStr }}
      </div>

      <div slot="to_pay" slot-scope="record">
        {{ record.to_pay | formatListPriceNumAndStr }}
      </div>

      <div slot="company_branch" slot-scope="record">
        <div class="truncate">{{ record.company_branch.name }}</div>
      </div>

      <div slot="locator" slot-scope="record">
        <aTooltip :title="record.locator">
          <div class="truncate">{{ record.locator }}</div>
        </aTooltip>
      </div>

      <div slot="payment_type" slot-scope="record">
        {{ record.payment_type }}
      </div>

      <div slot="paid_at" slot-scope="record">
        {{ record.paid_at | formatMultiDates }}
      </div>

      <div slot="expandedRowRender" slot-scope="record">
        <div style="padding: 20px 0 10px">
          <aRow>
            <aCol :span="6">
              <div class="cblack fw-600 pb-5 f10">Cliente</div>
              {{ record.customer.id }} - {{ record.customer.first_name }}
              {{ record.customer.last_name }}
            </aCol>

            <aCol :span="6">
              <div class="cblack fw-600 pb-5 f10">
                {{
                  record.product_type === "flight" ? "Viajante" : "Viajantes"
                }}
              </div>
              <div v-html="record.traveller_name"></div>
            </aCol>
          </aRow>
        </div>
      </div>

      <div class="travel-actions" slot="action" slot-scope="record">
        <a class="edit mr-5" @click="viewContract(record.id)">
          <aTooltip placement="left" title="Ver contrato">
            <a-icon class="f18" type="file-done" />
          </aTooltip>
        </a>

        <a class="edit" @click="viewSale(record.sale_id)">
          <aTooltip placement="left" title="Ver a venda">
            <aIcon class="f18" type="shopping"
          /></aTooltip>
        </a>
      </div>
    </aTable>

    <div class="a-center mt-20 mb-40">
      <aPagination
        v-model="currentPage"
        show-size-changer
        :page-size.sync="pageSize"
        :total="
          frontendFilters(newContractList(contract.list)).filteredList.length
        "
        :disabled="contract.loading"
        :pageSizeOptions="['10', '20', '30', '50', '100', '250', '500', '1000']"
        @showSizeChange="onShowSizeChange"
      />
    </div>
    <!-- selectedRows {{ selectedRows }}
    <hr />
    selectedPaxes {{ selectedPaxes }}
    <hr />
    invoicesInQueue {{ invoicesInQueue }} -->
    <!-- <jsonViewer
      :value="newContractList(contract.list)"
      :show-array-index="false"
      :expand-depth="1"
    /> -->

    <aModal
      class="sale-outer-modal"
      :title="false"
      :footer="false"
      :width="$root.windowWidth > 960 ? '1160px' : '100%'"
      v-model="openSaleModal"
      @cancel="openSaleModal = false"
    >
      <saleModal v-if="openSaleModal" :id="saleId" />
    </aModal>

    <aModal
      class="contract-details"
      width="884px"
      v-model="openContractModal"
      :title="false"
      :footer="false"
      @cancel="openContractModal = false"
    >
      <ContractModal v-if="openContractModal" :contractId="contractId" />
    </aModal>

    <aModal
      :footer="false"
      :width="$root.windowWidth > 960 ? '1100px' : '100%'"
      v-model="openGenerateInvoiceModal"
      @cancel="openGenerateInvoiceModal = false"
    >
      <template #title>
        <a-icon type="file" class="mr-5" /> GERAR FATURA
        <a-divider type="vertical" /> {{ tempInvoice.product_supplier_name }}
      </template>
      <GenerateInvoiceModal
        v-if="openGenerateInvoiceModal"
        :tempInvoice="tempInvoice"
        :invoicesInQueue="invoicesInQueue"
        @onFinishGenerateInvoice="onFinishGenerateInvoice"
        @onClickIsLoadingAction="onClickIsLoadingAction"
      />
    </aModal>
  </section>
</template>

<script>
import generateInvoicesCalcsAndRules from "@/components/finances/mixins/generateInvoicesCalcsAndRules";
import productSupplierMixins from "@/mixins/product-suppliers/productSupplierMixins.js";
import generateInvoiceMixins from "@/components/finances/mixins/generateInvoiceMixins.js";
import GenerateInvoiceModal from "@/components/finances/modals/GenerateInvoiceModal.vue";
import contractMixins from "@/components/contracts/mixins/contractMixins";
import SaleModal from "@/components/sales/modal/SaleModal.vue";
import ContractModal from "@/components/contracts/modals/ContractModal.vue";
import formatThings from "@/mixins/general/formatThings";

export default {
  props: {
    tempInvoice: Object,
    totalInvoicesInQueue: Number,
  },
  mixins: [
    generateInvoicesCalcsAndRules,
    productSupplierMixins,
    generateInvoiceMixins,
    contractMixins,
    formatThings,
  ],
  components: { SaleModal, ContractModal, GenerateInvoiceModal },
  data() {
    return {
      activeStatusTab: "Em aberto",
      openSaleModal: false,
      openContractModal: false,
      openGenerateInvoiceModal: false,
      contractId: "",
      saleId: "",
      filterContractId: "",
      productType: [],
      selectedPaxes: [],
      selectedRows: [],
      invoicesInQueue: [],
    };
  },

  mounted() {
    this.getSupplierPaxesInContract();
  },
  methods: {
    onFinishGenerateInvoice() {
      this.openGenerateInvoiceModal = false;
      this.selectedPaxes = [];
      this.selectedRows = [];
      this.invoicesInQueue = [];
      this.$emit("onFinishGenerateInvoice");
      this.$emit("getTempInvoiceDetails");
      this.getSupplierPaxesInContract();
    },
    onShowSizeChange(current, pageSize) {
      console.log(current, pageSize);
      this.currentPage = current;
      this.pageSize = pageSize;
    },
    onClickIsLoadingAction(status) {
      this.$emit("onClickIsLoadingAction", status);
    },
    frontendFilters(contractList) {
      const filteredList = contractList.filter((contract) => {
        const nameMatch = this.contract.filters.travellerName
          ? contract.traveller_name
              .toLowerCase()
              .includes(this.contract.filters.travellerName.toLowerCase())
          : true;

        const locatorMatch = this.contract.filters.locator
          ? contract.locator === this.contract.filters.locator
          : true;

        let statusFilter = false;

        if (
          this.activeStatusTab !== "Todos" &&
          this.activeStatusTab === contract.invoice_status
        )
          statusFilter = true;

        if (this.activeStatusTab === "Todos") statusFilter = true;

        let productTypeFilter = true;
        if (this.productType.length) {
          productTypeFilter = false;
          if (this.productType.includes(contract.product_type))
            productTypeFilter = true;
        }

        let contractIdFilter = true;
        if (this.filterContractId && this.filterContractId.length > 2) {
          contractIdFilter = contract.id == this.filterContractId;
        }

        return (
          nameMatch &&
          locatorMatch &&
          contract.supplier === this.tempInvoice.product_supplier_name &&
          statusFilter &&
          productTypeFilter &&
          contractIdFilter
        );
      });

      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;

      return {
        filteredList,
        filteredListCurrentPage: filteredList.slice(start, end),
      };
    },
  },
};
</script>

<style lang="css" scoped>
.fly-remove-paxes {
  position: fixed;
  bottom: 30px;
  right: 180px;
  z-index: 10;
}

.fly-add-bills {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 10;
}

.fly-add-bills .add {
  padding: 10px;
}
</style>
