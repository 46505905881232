import _ from "lodash";

export default {
  data() {
    return {
      loadingUpdateTempInvoice: false,
      loadingAddToSelectedPaxes: false,
      loadingRemoveSelectedPaxes: false,
      disabledStatusArr: ["Pendente", "Na fila", "Pago", "Recebido"],
      columns: [
        {
          title: "",
          scopedSlots: { customRender: "expandItem" },
          width: 40,
        },
        {
          title: "",
          scopedSlots: { customRender: "product_type" },
          width: 40,
        },
        {
          title: "Venda",
          dataIndex: "sale_id",
          key: "sale_id",
          sorter: (a, b) => a.sale_id - b.sale_id,
          defaultSortOrder: "descend",
          width: 90,
        },
        {
          title: "#",
          dataIndex: "id",
          key: "id",
          sorter: (a, b) => a.id - b.id,
          width: 80,
        },
        {
          title: "Localizador",

          scopedSlots: { customRender: "locator" },
          width: 100,
        },
        {
          title: "Produto",
          scopedSlots: { customRender: "product" },
          width: 180,
        },
        {
          title: "Dt. Lançamento",
          scopedSlots: { customRender: "release_date" },
          key: "release_date",
          width: 120,
        },
        {
          title: "Valor",
          key: "ticket_value",
          sorter: (a, b) => a.ticket_value - b.ticket_value,
          width: 130,
          scopedSlots: { customRender: "ticket_value" },
        },
        {
          title: "Tx. Embarque",
          key: "ticket_tax_value",
          sorter: (a, b) => a.ticket_tax_value - b.ticket_tax_value,
          width: 120,
          scopedSlots: { customRender: "ticket_tax_value" },
        },
        {
          title: "À receber",
          key: "incomings",
          sorter: (a, b) => a.incomings - b.incomings,
          width: 120,
          scopedSlots: { customRender: "incomings" },
        },
        {
          title: "À pagar",
          key: "to_pay",
          sorter: (a, b) => a.incomings - b.incomings,
          width: 120,
          scopedSlots: { customRender: "to_pay" },
        },

        {
          title: "Dt. Pagamento",
          scopedSlots: { customRender: "paid_at" },
          key: "paid_at",
          width: 120,
        },

        {
          title: "Filial",
          scopedSlots: { customRender: "company_branch" },
          width: 140,
          sorter: (a, b) =>
            a.company_branch.name.length - b.company_branch.name.length,
        },
        {
          title: "Status",
          key: "invoice_status",
          dataIndex: "invoice_status",
          width: 170,
          sorter: (a, b) => a.audit_status.length - b.audit_status.length,
        },
        {
          title: "",
          key: "action",
          align: "right",
          width: 103,
          scopedSlots: { customRender: "action" },
        },
      ],

      currentPage: 1,
      pageSize: 20,
    };
  },
  filters: {
    formatPeriod(period) {
      let thePeriod = period.split("|");
      return `${thePeriod[0].split("-").reverse().join("/")} a ${thePeriod[1]
        .split("-")
        .reverse()
        .join("/")}`;
    },
  },
  methods: {
    onChangePaxesStatusTabs(tab) {
      this.activeStatusTab = tab;
      this.filterInvoiceStatus = tab === "Todos" ? "" : tab;
      this.contract.filters.tempInvoiceId = "";
      this.currentPage = 1;

      this.disabledStatusArr = ["Pendente", "Na fila", "Pago", "Recebido"];
      this.selectedPaxes = [];
      this.selectedRows = [];

      if (tab == "Na fila") {
        this.disabledStatusArr = ["Pendente", "Pago", "Recebido"];
        this.contract.filters.tempInvoiceId = this.tempInvoice.id;
        this.getSupplierPaxesInContract();
      } else {
        this.invoicesInQueue = [];
        this.getSupplierPaxesInContract();
      }
    },
    async getSupplierPaxesInContract() {
      this.contractUrlDefaults();
      this.contract.filters.productSupplier =
        this.tempInvoice.product_supplier_name;
      this.contract.pagination.perPage = 1700;
      this.contract.filters.period.selected =
        this.tempInvoice.period.split("|");
      this.contract.filters.status.selected = ["concluded"];
      await this.getContracts();
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      if (this.activeStatusTab === "Na fila") {
        this.invoicesInQueue = selectedRows;
        this.selectedPaxes = selectedRowKeys;
        this.selectedRows = [];
      }

      if (this.activeStatusTab !== "Na fila") {
        this.invoicesInQueue = [];
        this.selectedPaxes = selectedRowKeys;
        this.selectedRows = [...this.selectedRows, ...selectedRows];
      }

      // Verification if selectedRows entries are contained in selectedPaxes
      const allRowsInPaxes = this.selectedRows.every((row) =>
        this.selectedPaxes.includes(row.key)
      );
      if (allRowsInPaxes) {
        console.log("All selected rows are contained in selected paxes.");
      } else {
        console.log("Some selected rows are not contained in selected paxes.");
        // Remove entries from selectedRows that are not in selectedPaxes
        this.selectedRows = this.selectedRows.filter((row) =>
          this.selectedPaxes.includes(row.key)
        );
      }
    },
    async onClickRemoveToSelectedPaxes() {
      this.loadingRemoveSelectedPaxes = true;
      this.$emit("onClickIsLoadingAction", true);
      await this.updateTempInvoiceOnRemovePaxes();
      await this.getSupplierPaxesInContract();
      await this.stopAddToSelectedPaxes();
    },
    async updateTempInvoiceOnRemovePaxes() {
      const tempInvoice = this.tempInvoice.raw;

      tempInvoice.selected_paxes = JSON.parse(tempInvoice.selected_paxes);

      let newSelectedPaxes = _.difference(
        tempInvoice.selected_paxes,
        this.selectedPaxes
      );

      tempInvoice.selected_paxes = JSON.stringify(newSelectedPaxes);

      let selectedContracts = [];
      let selectedSales = [];

      newSelectedPaxes.forEach((key) => {
        selectedContracts.push(key.split("-")[0]);
        selectedSales.push(key.split("-")[1]);
      });

      for (const contract of this.invoicesInQueue)
        await this.updateContractData(contract, "", "Em aberto");

      tempInvoice.selected_contract_ids = [...new Set(selectedContracts)].join(
        ","
      );
      tempInvoice.selected_sales_ids = [...new Set(selectedSales)].join(",");

      this.invoicesInQueue = [];

      try {
        const data = await this.$http.post(
          `/temporary-invoice/update`,
          tempInvoice
        );
        this.$emit("onCreateLoadTempInvoicesList");
        return data;
      } catch (e) {
        e;
      } finally {
        this.loadingUpdateTempInvoice = false;
      }
    },
    async onClickAddToSelectedPaxes() {
      if (this.selectedPaxes.length === 0) {
        this.$message.info("Selecione um grupo de paxes na tabela.");
        return;
      }

      this.$emit("onClickIsLoadingAction", true);

      let groupName = `Group ${this.getRandomInt(999)}`;

      this.loadingAddToSelectedPaxes = true;
      await this.updateTempInvoice(groupName);
      await this.getSupplierPaxesInContract();
      await this.stopAddToSelectedPaxes();
    },
    async stopAddToSelectedPaxes() {
      this.loadingAddToSelectedPaxes = false;
      this.loadingRemoveSelectedPaxes = false;
      this.selectedPaxes = [];
      this.selectedRows = [];
      this.$emit("onClickIsLoadingAction", false);
      this.$emit("getTempInvoiceDetails");
    },
    async updateTempInvoice(groupName) {
      this.loadingUpdateTempInvoice = true;

      const tempInvoice = this.tempInvoice.raw;

      if (!["", "[]"].includes(tempInvoice.selected_paxes)) {
        tempInvoice.selected_paxes = JSON.parse(tempInvoice.selected_paxes);
        tempInvoice.selected_paxes = [
          ...tempInvoice.selected_paxes,
          ...this.selectedPaxes,
        ];
        tempInvoice.selected_paxes = JSON.stringify(tempInvoice.selected_paxes);
      } else {
        tempInvoice.selected_paxes = JSON.stringify(this.selectedPaxes);
      }

      this.tempInvoice.raw.selected_contract_ids;

      let selectedContracts =
        this.tempInvoice.raw.selected_contract_ids !== ""
          ? this.tempInvoice.raw.selected_contract_ids.split(",")
          : [];

      let selectedSales =
        this.tempInvoice.raw.selected_sales_ids !== ""
          ? this.tempInvoice.raw.selected_sales_ids.split(",")
          : [];

      this.selectedPaxes.forEach((key) => {
        selectedContracts.push(key.split("-")[0]);
        selectedSales.push(key.split("-")[1]);
      });

      for (const contract of this.selectedRows)
        await this.updateContractData(contract, groupName);

      tempInvoice.selected_contract_ids = [...new Set(selectedContracts)].join(
        ","
      );
      tempInvoice.selected_sales_ids = [...new Set(selectedSales)].join(",");

      try {
        const data = await this.$http.post(
          `/temporary-invoice/update`,
          tempInvoice
        );
        this.$emit("onCreateLoadTempInvoicesList");
        return data;
      } catch (e) {
        e;
      } finally {
        this.loadingUpdateTempInvoice = false;
      }
    },
    async updateContractData(contract, groupName, status) {
      let constractData = {};

      console.log("updateContractData before", contract, contract.product_id);

      if (["Serviços", "Reserva Online"].includes(contract.package_type)) {
        if (contract.product_type == "flight") {
          constractData = {
            id: contract.id,
            [`contract_finances_${contract.product_type}_${contract.product_id}_traveller_${contract.traveller_id}_invoice_status`]:
              status ?? "Na fila",
            [`contract_finances_${contract.product_type}_${contract.product_id}_traveller_${contract.traveller_id}_invoice_group`]:
              groupName,
            [`contract_finances_${contract.product_type}_${contract.product_id}_traveller_${contract.traveller_id}_temp_invoice_id`]:
              status ? "" : this.tempInvoice.id,
          };
        } else {
          console.log(
            "updateContractData after",
            contract,
            contract.product_id
          );
          constractData = {
            id: contract.id,
            [`contract_finances_${contract.product_type}_${contract.product_id}_invoice_status`]:
              status ?? "Na fila",
            [`contract_finances_${contract.product_type}_${contract.product_id}_invoice_group`]:
              groupName,
            [`contract_finances_${contract.product_type}_${contract.product_id}_temp_invoice_id`]:
              status ? "" : this.tempInvoice.id,
          };
        }
      } else {
        constractData = {
          id: contract.id,
          [`contract_finances_${contract.product_type}_invoice_status`]:
            status ?? "Na fila",
          [`contract_finances_${contract.product_type}_invoice_group`]:
            groupName,
          [`contract_finances_${contract.product_type}_temp_invoice_id`]: status
            ? ""
            : this.tempInvoice.id,
        };
      }

      try {
        const data = await this.$http.post(
          `/contract/update-multi-meta`,
          constractData
        );
        return data;
      } catch (e) {
        console.log(e);
      }
    },
    viewSale(id) {
      this.openSaleModal = true;
      this.saleId = id;
    },
    viewContract(id) {
      this.openContractModal = true;
      this.contractId = id;
    },
    getRandomInt(max) {
      return Math.floor(Math.random() * max);
    },
  },
};
